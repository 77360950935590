import React from 'react';
import { Row, media, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import {
  CustomerQuote,
  HighlightedHeadline,
  SecondaryButton,
} from 'components/Shared';
import { ContentGrid } from './NamedSlice';
import Imgix from 'react-imgix';
import * as PropTypes from 'prop-types';

const SliceContainer = styled.div`
  position: relative;
  min-height: 100vh;
  z-index: 10;
  text-align: center;
  justify-content: center;
  overflow: hidden;
  padding: 16px 0px;
  ${media.md`
      padding: 64px 0px;
  `}
  ${media.xxl`
      padding: 112px 0px 64px 0px;
  `}
`;

const ContentColumn = styled(Col)`
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  margin: 0px 32px;
  padding: 16px 16px 0px 16px;
  text-align: left;
  ${media.md`
      text-align: center;
  `}
`;

const StyledButtonColumn = styled(ContentColumn)`
  text-align: center;
  padding: 0px 16px 32px 16px;
  ${media.md`
      padding: 16px 16px;
  `}
`;

export const SliceText = styled.p`
  padding: 0px;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

const SliceContent = styled.div`
  padding: 0px;
  color: ${({ theme }) => theme.palette.secondary.main};

  p {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const Image = styled(Imgix)`
  object-fit: contain;
  width: 100%;
  padding: 24px 0px;
`;

const StyledsContentColumn = styled(ContentColumn)`
  margin-bottom: 12px;
`;

function PrimaryButton(props) {
  return null;
}

PrimaryButton.propTypes = {
  onClick: PropTypes.func,
  size: PropTypes.string,
  children: PropTypes.node,
};
const CaseStudyTeaserSlice = ({
  data: {
    button_text,
    navigation_link,
    headline_pre_highlight: pre_highlight,
    headline_highlight: highlight,
    headline_post_highlight: post_highlight,
    case_study_image: image,
    text,
    testimonial_quote,
  },
}) => {
  return (
    <SliceContainer>
      <ContentGrid>
        <Row justifyContent="center">
          <StyledsContentColumn col={12} md={11}>
            <HighlightedHeadline
              pre={pre_highlight}
              highlight={highlight}
              post={post_highlight}
            />
          </StyledsContentColumn>
        </Row>
        <Row justifyContent="center">
          <ContentColumn col={12} md={10}>
            {!!text?.html ? (
              <SliceContent dangerouslySetInnerHTML={{ __html: text.html }} />
            ) : (
              <SliceText>{text.text}</SliceText>
            )}
          </ContentColumn>
        </Row>
        <Row justifyContent="center">
          <ContentColumn col={12} md={8}>
            <Image
              className="lazyload"
              src={image.url}
              htmlAttributes={{
                alt: image.alt,
              }}
              sizes="100vw"
              attributeConfig={{
                src: 'data-src',
                srcSet: 'data-srcset',
                sizes: 'data-sizes',
              }}
            />
          </ContentColumn>
        </Row>
        {testimonial_quote.document && (
          <Row justifyContent="center">
            <ContentColumn col={12}>
              <CustomerQuote quote_data={testimonial_quote.document.data} />
            </ContentColumn>
          </Row>
        )}
        {button_text.text && (
          <Row justifyContent="center">
            <StyledButtonColumn xl={3} lg={4} md={5}>
              <SecondaryButton to={navigation_link.url} size="small">
                {button_text.text}
              </SecondaryButton>
            </StyledButtonColumn>
          </Row>
        )}
      </ContentGrid>
    </SliceContainer>
  );
};

export default CaseStudyTeaserSlice;
