import React, { createContext, useState, useEffect } from 'react';
import { DemoDialog, ScheduleDemoDialog } from 'components/Shared';
import axios from 'axios';
import { graphql, useStaticQuery } from 'gatsby';
import { getRelevantNode } from 'utils/helpers';
import { globalHistory } from '@reach/router';
import { useTheme } from 'src/hooks';

const RequestDemoContext = createContext();
const submitURL = `https://api.hsforms.com/submissions/v3/integration/submit/5223531/e83d6e3e-c8ef-4f73-9e02-afac781ab7a8
`;

const RequestDemoProvider = ({ pageLang = 'en-us', children }) => {
  const { allPrismicPageNavigation = {} } = ({} = useStaticQuery(query));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [schedulingDialogOpen, setScheduleDialogOpen] = useState(false);
  const [email, setEmail] = useState('');
  const { palette } = useTheme();

  const data = getRelevantNode(allPrismicPageNavigation, pageLang).data;

  const handleOpenScheduling = () => {
    setScheduleDialogOpen(true);
  };

  const handleOpenDialog = (email = '') => {
    setDialogOpen(true);
    setEmail(email);
  };

  const handleCloseDialog = () => setDialogOpen(false);
  const onSubmit = async (values) => {
    const result = await axios.post(submitURL, {
      ...valuesToHubspotBody({ ...values, lang: pageLang }),
    });

    return result;
  };

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') setDialogOpen(false);
    });
  }, [setDialogOpen]);

  return (
    <RequestDemoContext.Provider
      value={{
        handleOpenScheduling,
        openDemoDialog: handleOpenDialog,
      }}
    >
      <DemoDialog
        key={`demo-dialog-${dialogOpen}`}
        initialValues={{ email }}
        open={dialogOpen}
        onClose={handleCloseDialog}
        handleFormSubmit={onSubmit}
        pageLang={pageLang}
      />
      <ScheduleDemoDialog
        open={schedulingDialogOpen}
        onClose={() => setScheduleDialogOpen(false)}
        bookDemoLink={data?.book_demo_link?.url}
      />
      {children}
    </RequestDemoContext.Provider>
  );
};

const valuesToHubspotBody = ({ values, privacyText, lang = '' }) => {
  return {
    fields: [
      {
        name: 'email',
        value: values.email,
      },
      {
        name: 'language',
        value: lang.substring(0, 2),
      },
    ],
    context: {
      pageUri: window.location.pathname,
      pageName: 'seedtrace website',
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: values.privacyAccepted,
        text: privacyText,
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text: 'Marketing accepted',
          },
        ],
      },
    },
  };
};

export { RequestDemoProvider };

export default RequestDemoContext;

const query = graphql`
  query RequestDemoQuery {
    allPrismicPageNavigation {
      nodes {
        lang
        tags
        data {
          book_demo_link {
            url
          }
        }
      }
    }
  }
`;
